@import '../../../colors';


$span-inset: 2em;


.miniature-wrapper {
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:active {
        box-shadow: 2px 2px 0.5em .2em rgba($color-object-light, 0.8);
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }

    .img-wrapper {
        height: 80%;
        width: auto;
        border-radius: 1em;
        z-index: 62;
        margin-bottom: -$span-inset;

        img {
            width: 100%;
            height: 100%;

            object-fit: cover;
            border-radius: 1em;
            z-index: 61;

        }
    }

    span {
        padding-top: $span-inset;
        padding-bottom: 0.5em;
        width: 100%;
        align-content: end;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        font-size: 1em;
        border: solid 0.1em $color-secondary;
        border-top: none;
        border-radius: 0em 0em 0.5em 0.5em;
        background: linear-gradient(to right, rgba($color-secondary, 0.5) 0%, rgba(255, 0, 0, 0) 48%, rgba($color-secondary, 0.5) 100%) $color-secondary-light;

        border-width: 0 0.1em 0.1em 0.1em;
        z-index: 60;
        font-weight: 700;
        color: $color-text-dark;

        &.secondary {
            border: solid 0.1em $color-object;
            background: linear-gradient(to right, rgba($color-object-dark, 0.4) 0%, rgba(255, 0, 0, 0) 30%, rgba(255, 0, 0, 0) 70%, rgba($color-object-dark, 0.4) 100%) $color-object;
            color: $color-text-light;
        }
    }
}


.character-wrapper {
    $span-inset: 0em;
    box-shadow: 2px 2px 0.5em .2em rgba($color-secondary-dark, 0.6);
    transition: all 0.2s ease-in-out;

    span {
        display: flex;
        font-weight: bolder;
        padding-top: $span-inset;
        overflow: hidden;
        width: 100%;
        height: 50%;
        text-wrap: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 0.2em;

        border-color: $color-secondary;
        border-width: 0.1em;
        border-style: solid;
        border-top: none;
        border-radius: 0.5em 0.5em 0.5em 0.5em;
    }

    .img-wrapper {
        margin-bottom: -$span-inset;

        img {
            max-width: 98%;
            border-color: $color-secondary;
            border-width: 0.1em;
            border-style: solid;
            border-bottom: none;

            border-radius: 0.5em 0.5em 0 0;
            box-shadow: 0 -1px 0 2px $color-secondary;
            background-color: $color-secondary-dark;
        }
    }

    height: 100%;

    &:hover {
        box-shadow: 0 0 0.5em 0.5em rgba($color-object-light, 0.8);
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }
}

.used{
    .username{
        position:absolute;
        color: $color-text-light;
    }
    filter: grayscale(100%);
}