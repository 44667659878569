
@function toRgb($color) {
    $red : red($color);
    $green : green($color);
    $blue : blue($color);

    @return rgb($red, $green, $blue);
}

$color-text-light: rgb(26, 25, 25);
$color-text-dark: rgb(247, 243, 252);

$color-disabled: #676170;
$color-disabled-dark: toRgb(darken($color-disabled, 20%));

$color-primary: #112a4d;
$color-primary-dark: toRgb(darken($color-primary, 5%));
$color-primary-light: toRgb(lighten($color-primary, 20%));

$color-secondary: #183618;
$color-secondary-dark: toRgb(darken($color-secondary, 10%));
$color-secondary-light: toRgb(lighten($color-secondary, 20%));

$color-background: #054d69;
$color-background-dark: toRgb(darken($color-primary, 20%));
$color-background-light: toRgb(lighten($color-primary, 20%));

$color-object: #ffcc00;
$color-object-dark: toRgb(darken($color-object, 30%));
$color-object-light: toRgb(lighten($color-object, 20%));


$color-success: #ffbc42;
$color-success-dark: toRgb(darken($color-success, 30%));
$color-success-light: toRgb(lighten($color-success, 20%));

$color-error: #FF0054;
$color-error-dark: toRgb(darken($color-error, 20%));
$color-error-light: toRgb(lighten($color-error, 20%));

$color-warning: #a3e071;
$color-warning-dark: toRgb(darken($color-warning, 20%));
$color-warning-light: toRgb(lighten($color-warning, 20%));


$color-magic: #ff758f;
$color-magic-dark: toRgb(darken($color-magic, 20%));
$color-magic-light: toRgb(lighten($color-magic, 20%));
