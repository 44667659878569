// declare color based on SUCCESS,ERROR,WARNING,INFO
@import 'colors';

.panel {
    background: linear-gradient(to right, rgba($color-primary-light, 0.4) 0%, rgba(255, 0, 0, 0) 50%, rgba($color-primary, 0.4) 100%) $color-primary-light;
    box-shadow: 0px 0px 0px 1px rgba($color-primary-light, 0.25) inset, 0px 0px 1px 2px rgba(255, 255, 255, 0.25) inset, 0px -5px 50px -15px rgba($color-secondary, 1) inset, 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.5);
    border-radius: 0.5em;

    &.secondary {
        background: linear-gradient(to right, rgba($color-secondary-light, 0.4) 0%, rgba(255, 0, 0, 0) 50%, rgba($color-secondary-light, 0.4) 100%) $color-secondary;
        box-shadow: 0px 0px 0px 1px rgba($color-secondary-light, 0.25) inset, 0px 0px 1px 2px rgba(255, 255, 255, 0.25) inset, 0px -5px 50px -15px rgba($color-secondary-light, 1) inset, 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.5);
        border-radius: 0.5em;
        color : $color-text-dark;
    }

    &.object {
        background: linear-gradient(to right, rgba($color-object, 0.4) 0%, rgba(255, 0, 0, 0) 50%, rgba($color-object, 0.4) 100%) $color-object-light;
        box-shadow: 0px 0px 0px 1px rgba($color-object, 0.25) inset, 0px 0px 1px 2px rgba(255, 255, 255, 0.25) inset, 0px -5px 50px -15px rgba($color-object, 1) inset, 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.5);
        border-radius: 0.5em;
        color : $color-text-light;
    }

	&.object-dark{
		background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba($color-object-dark, 0.4) 50%, rgba(0, 0, 0, 0.4) 100%) $color-object-dark;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25) inset, 0px 0px 1px 2px rgba($color-background-dark, 0.25) inset, 0px -5px 50px -15px rgba($color-object-light, 1) inset, 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.5);
		text-shadow: 0 5px 5px rgba($color-object, 0.4);
        color : $color-text-dark;
	}
}

.section {
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    &>* {
        margin: 0.5em;
    }

    height: auto;
}

.smallText {
    max-width: 10em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.object-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    align-content: flex-start;
    margin-bottom: 0;

    height: 100%;
    width: 100%;
    overflow: auto;

    transition: all 0.2s ease-in-out;
    background-image: url('/images/drawer.jpg');
    border-radius: 1em;
    box-shadow: inset 0px 0px 0px 1px rgba($color-background-dark, 0.2), inset 0px 0px 1px 2px rgba($color-background-dark, 0.2), inset 0px -5px 50px -15px rgba($color-background-dark, 0.4), 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.5), inset 0px 0px 1em 1em rgba($color-background-dark, 0.7), ;
    border: solid 0.1em $color-background-dark;

    $shadow-amount: 0.3;
    &>* {
        padding: 0.25em;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        width: 20%;
        background: linear-gradient(to right, rgba(0, 0, 0, $shadow-amount) 0%, rgba($color-background-dark, $shadow-amount+0.3) 40%, rgba($color-background-dark, $shadow-amount+0.3) 60%, rgba(0, 0, 0, $shadow-amount) 100%);
        box-shadow: 0px 0px 0.4em 0.4em rgba($color-background-dark, $shadow-amount);
    }

    .object-item:hover {
        padding: 0.25em;
        transition: all 0.2s ease-in-out;
        box-shadow: 0px 0px 0px 1px rgba($color-object, 0.25) inset, 0px 0px 1px 2px rgba($color-object-light, 0.25) inset, 0px -5px 50px -15px rgba($color-object, 1) inset, 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.5);
    }
}

.qr-code{
    background: white;
    padding : 1em;
    width: 100%;
}