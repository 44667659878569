.header {
    padding: 0.1em 0.5em;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-small {
    padding: 0.4em;
    margin: 0.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        font-size: 1rem;
    }
}

.logout{
    padding: 0;
}