@import 'colors';

$primary-color: $color-primary;
$primary-color-dark: $color-primary-dark;
$primary-color-light: $color-primary-light;
$primary-text-color: $color-text-dark;

$secondary-color: $color-secondary;
$secondary-color-dark: $color-secondary-dark;
$secondary-color-light: $color-secondary-light;
$secondary-text-color: $color-text-dark;

input,
select,
textarea {
	appearance: none;
	border: none;
	outline: none;
	border-bottom: .2em solid $primary-color;
	background: rgba($primary-color, .2);
	border-radius: .2em .2em 0 0;
	padding: .4em 1em;
	color: $color-text-dark;
	font-size: large;

	&::placeholder {
		color: rgba($primary-color-dark,0.5);
		font-weight: 500;
	}
}

input,textarea,.select-wrapper{
	width: auto;
	min-width: 40%;
	max-width: 100%;
	color: $color-text-dark;
}

select{
	padding-right: 2em;
}

.select-wrapper .select-arrow {
	position: absolute;
	width:0;
	height:0;
	color: $color-text-dark;
	
	&::after {
		content: '▼';
		position: absolute;
		right: 0.5em;
		top: 0.3em;
		font-size: 0.8em;
		color: rgba($primary-color-dark,0.5);
	}

	&:focus::after {
		transform: rotate(180deg);
	}
}



button {
	background-image: none;
	border-radius: 4px;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	margin: 0 5px 10px 0;
	overflow: visible;
	padding: 12px 40px;
	text-align: center;
	text-transform: none;
	touch-action: manipulation;
	user-select: none;
	-webkit-user-select: none;
	vertical-align: middle;
	white-space: nowrap;

	background-size: 200% 100%;
	background-position: 100% 0;
	transition: all 0.2s ease-in-out;

	&:hover {
		background-position: 0 0;
		transition: all 0.2s ease-in-out;
	}

	background-image: linear-gradient(to left, $primary-color-light, $primary-color-dark);
	background-color: $primary-color;
	border: 1px solid $primary-color;
	box-shadow: $primary-color-dark 4px 4px 0 0, $primary-color 4px 4px 0 1px;
	color: $primary-text-color;
	text-shadow: 0 5px 5px rgba($primary-color-light, 0.4);

	&.secondary {
		background-image: linear-gradient(to left, $secondary-color-light, $secondary-color-dark);
		background-color: $secondary-color;
		border: 1px solid $secondary-color;
		box-shadow: $secondary-color-dark 4px 4px 0 0, $secondary-color 4px 4px 0 1px;
		color: $secondary-text-color;
		text-shadow: 0 5px 5px rgba($secondary-color-light, 0.4);
	}

	&.object{
		border: 1px solid $color-object;
		background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba($color-object-light, 0.4) 50%, rgba(0, 0, 0, 0.4) 100%) $color-object-light;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25) inset, 0px 0px 1px 2px rgba(255, 255, 255, 0.25) inset, 0px -5px 50px -15px rgba($color-object-dark, 1) inset, 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.5);
		text-shadow: 0 5px 5px rgba($color-object-light, 0.4);
        color : $color-text-light;
	}
	&.object-dark{
		border: 1px solid $color-object-dark;
		background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba($color-object-dark, 0.4) 50%, rgba(0, 0, 0, 0.4) 100%) $color-object-dark;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25) inset, 0px 0px 1px 2px rgba(255, 255, 255, 0.25) inset, 0px -5px 50px -15px rgba($color-object-light, 1) inset, 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.5);
		text-shadow: 0 5px 5px rgba($color-object-dark, 0.4);
        color : $color-text-dark;
	}
}

button:disabled{
	background-image: none;
	background-color: $color-disabled;
	border: 1px solid $color-disabled;
	box-shadow: $color-disabled-dark 4px 4px 0 0;
	color: $color-text-light;
	transition: all 0.2s ease-in-out;
}