@import './colors';

//reset 
* {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    box-sizing: border-box;
}

html {
    height: 120vh;
    width: 100vw;
    overflow-y: auto;
    background: #000000;
}

@media print {
    html {
        height: inherit;
        width: inherit;
    }
}

body,
div#root {
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;

    width: 100%;
    height: 100%;
    background: #000000;
    background: linear-gradient(to right, rgba($color-background, 0.4) 0%, rgba($color-background-light, 0.5) 50%, rgba($color-background, 0.4) 100%) $color-primary-dark;
    box-shadow: 0px 0px 5em 1px rgba($color-background-dark, 0.25) inset, 0px 0px 1px 2px rgba($color-primary-dark, 0.25) inset, 0px -5px 50px -15px rgba($color-background-dark, 1) inset, 0px 0px 5em rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.5);
    color : $color-text-dark;
}

div.app-root {
    width: min(100%, max(50%,700px));
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;

    &>* {
        width:100%;
        flex: 0 1 auto;
    }
}

.full-page-debug {
    background: white;
    color : black !important;
    height: 100%;
    width: 100%;
    overflow: auto;
    & * {
        width:fit-content;
        flex: 0 1 auto;
    }
    .qr-code{
        width : auto
    }
}