@import '../../colors';

.character-list-wrapper {
    height: 100%;
    width: 100%;
    padding-top: 0.5em;
    margin-top: 0.5em;
    overflow: auto;
    display: flex;

}

.frame {
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    height: fit-content;
    border: solid 2em $color-object;
    border-image: url("../../images/frame.png") 25% round;
    background-image: url("../../images/canvas.jpg");

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;

}

.frame>* {
    width: 50%;
    margin-bottom: 1em;
}

.character-page {
    overflow: auto;
    padding : 0.5em;
    

    .character-head {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        &>* {
            flex: 1 1 auto;
            max-width: 35%;
        }

        .names{
            width : 100%;

            button{
                padding: 1em;
            }
        }
    }

    .char-img-wrapper {
        flex: 1 0 auto;
        max-width: 50%;
    }

    img {
        border: solid 2em $color-object;
        border-image: url("../../images/frame.png") 25% round;

        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .presentation{
        
    }
}