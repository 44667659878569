@import '../../colors';

.head {
    font-size: 0.8em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95% !important;
    padding: 0.5em 0em 0.5em 0.5em;
    margin: 0%;

    &>* {
        width: 30%;
        height: auto;
        align-content: center;
        align-items: center;
    }

    button {
        padding: 0.5em 0;
        width: 100%
    }
    .head-actions button {
        font-size: smaller;
    }

    button.qr-scan {
        height: max(10vw, 2em);
        width: max(10vw, 2em);
        border-radius: 100%;
        font-size: 3.5em;
        text-align: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .character {
        height: 100%;
        max-height: 15vmax;
    }

}

.party-active-content {
    flex: 1 0 auto;
    margin: 0 0.5em;
    overflow: auto;
    height: 100%;
    padding-top: 0.5em;
}

.log-display{
    padding : 0.5em;
    height: 100%;
    width: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;


    .logs{
        border-radius: 0.5em;
        background-image: url(../../images/canvas.jpg);
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
        overflow: auto;
        color : black;
    }

    .actions{
        margin: 0;
        padding: 0;
        width: 100%;
        form{
            width: 100%;
            display: flex;
            flex-direction: row;
        }
        
    }
}

.scenarios{
    height: 100%;
    overflow: auto;
    padding:0.5em;
    font-size: 0.7em;
}

.scenario{
}