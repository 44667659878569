@import '../../colors';

.primary {
    background: linear-gradient($color-background,$color-background-light);
    border-color: $color-background-dark;
}

.success {
    background: linear-gradient($color-success,$color-success-light);
    border-color: $color-success-dark;
}

.error {
    background: linear-gradient($color-error,$color-error-light);
    border-color: $color-error-dark;
}

.warning {
    background: linear-gradient($color-warning-light,$color-warning);
    border-color: $color-warning-dark;
}

.info {
    background: linear-gradient($color-object,$color-object-light);
    border-color: $color-object-dark;
}

.magic {
    background: linear-gradient($color-magic, $color-magic-light);
    border-color: $color-magic-dark;
}

.dialog-box-icon-wrapper{
    width: 0px;
    height: 0px;
}

.dialog-box-icon-content{
    //sjould be display at the top left corner
    position: absolute;
    top: 0;
    left: 0;
    margin: 3%;
    font-size: larger;
}

.dialog-box-wrapper {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: min(100vw, 100vmax);
    height: min(100vh, 100vmax);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-box-backdrop {
    z-index: 1001;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vmax;
    height: 100vmax;
    background-color: rgba($color-background-dark, 0.2);
    backdrop-filter: blur(5px);
}

.dialog-box-body {
    z-index: 1002;
    position: relative;
    padding: 1em;
    max-width: 70%;
    max-height: 70%;
    margin: 20%;
    border-radius: 10px;
    border-width: 4px;
    border-style: solid;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.dialog-box-content {
    padding: 2em;
}

.dialog-box-actions {
    display : flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}