@import '../../colors';

.qrreader-wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    padding: 0;
    margin : 0;

    &::after {
        content: ""; 
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba($color-background-dark, 0.9);
        z-index: 999;
    }
}

.qrreader-body {
    height : 100%;
    
    img{
        position : absolute;
        width: min(80vw,80vh);
        z-index: 1005;
    }
}

.qrreader-camera {
    border-radius: 100%;
    width: min(80vw,80vh);
    height: min(80vw,80vh);

    & * {
        border-radius: 100%;
    }

    animation: loading-shadow 2s infinite;

    video {
        z-index: 1001;
    }

    .qr-viewfinder-wrapper {
        position: absolute;
        width: 0;
        height: 0;

        div {
            position: relative;
            width: min(80vw,80vh);
            height: min(80vw,80vh);
            border: 1vw solid rgba($color-background-dark, 1) !important;
            animation: loading-shadow 2s infinite;
            z-index: 1002;
        }
    }
}

@keyframes loading-shadow {
    0% {
        box-shadow: rgba($color-background-dark, 1) 0px 0px 5vw 5vw inset,
            rgba($color-primary-light, 0.6) 3vw -3vw 3vw 3vw inset;
    }

    25% {
        box-shadow: rgba($color-background-dark, 1) 0px 0px 5vw 5vw inset,
            rgba($color-primary-light, 0.6) 3vw 3vw 3vw 3vw inset;
    }

    50% {
        box-shadow: rgba($color-background-dark, 1) 0px 0px 5vw 5vw inset,
            rgba($color-primary-light, 0.6) -3vw 3vw 3vw 3vw inset;
    }

    75% {
        box-shadow: rgba($color-background-dark, 1) 0px 0px 5vw 5vw inset,
            rgba($color-primary-light, 0.6) -3vw -3vw 3vw 3vw inset;
    }

    100% {
        box-shadow: rgba($color-background-dark, 1) 0px 0px 5vw 5vw inset,
            rgba($color-primary-light, 0.6) 3vw -3vw 3vw 3vw inset;
    }
}
