.object-detail-wrapper {
    padding : 0.5em;
    overflow: auto;

    .actions{
        
    }
}

.object-detail-head {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &>* {
        max-height: 20%;
        width: auto
    }

    img {
        max-width: 100%;
        height: min(40vw, 40vh);
    }
}

.combine-wrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-content: flex-start;
    margin-top: 0.5em;
    padding: 0.5em;
    padding-top: 1em;
    
    .all-objects{
        display: flex;
        height: 100%;

        &>*{
            height: 100%;
            flex: 1 0 auto;
        }
    }
}